import React, { useContext } from "react";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProductsComponent from "./ProductsComponent";
import { ProductsContext } from "../utils/context";
import "react-multi-carousel/lib/styles.css";
import {Zoom} from "react-awesome-reveal"

const ProjectsSection = () => {
  const navigate = useNavigate();
  const [t] = useTranslation("global");
  const { products } = useContext(ProductsContext);

  const handleClick = () => {
    navigate("/projects");
  };
  return (
    <div className="mt-4">
       <Zoom direction="in">
        <h1 className="flex justify-center text-center text-3xl text-accent font-bold">
          {t("LATEST PROPERTIES PUBLISHED")}
        </h1>
        </Zoom>
      <ProductsComponent numProducts={6} products={products} />
      <Zoom direction="in">
        <div className="p-10 mt-2 flex justify-center">
          <button
            onClick={handleClick}
            className="xl:mx-0 bg-[#CBA660] rounded-full px-[30px] h-[55px] text-white font-medium py-2 flex flex-row gap-2 items-center text-sm lg:text-base"
          >
            {t("Display All Projects")} <FaChevronRight />
          </button>
        </div>
        </Zoom>
    </div>
  );
};

export default ProjectsSection;
