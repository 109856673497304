import React from "react";
import about from "../assets/horaires.jpeg";
import { Link } from "react-router-dom";

const Honoraires = () => {
  return (
    <div>
      <div className="relative h-[320px] m-8 overflow-hidden rounded-lg">
        <div className="absolute z-30 flex flex-col items-center w-full h-full px-6 py-8 text-[#CBA660] md:py-10 font-semibold">
          <h2 className="text-xl text-center ms:text-3xl md:text-3xl lg:text-3xl xl:text-3xl mt-16 uppercase">
            Nos Honoraires
          </h2>
          <div className="max-w-2xl mt-5 font-light text-center text-white">
            <p>
              Mettez-vous au courant de nos honoraires transparents et
              compétitifs. Chez EssenLux, nous croyons en la transparence et
              l’équité dans nos tarifs. Découvrez nos frais de service
              clairement définis et notre approche client centrée.
            </p>
          </div>
        </div>
        <div className="absolute top-0 right-0 w-full h-full">
          <img
            alt="Snowy mountain lake"
            className="object-cover w-full h-full"
            src={about}
          />
        </div>
      </div>

      <section class="max-w-3xl mx-auto">
        <div class="p-3 prose md:py-20 space-y-5">
          <div class="text-text">
            <div class="col-md-12 font-light text-gray-600">
              <p>
                Lorsque vous vendez entre particuliers, vous êtes seul pour
                tenter d’estimer au plus juste la valeur correcte de votre bien
                immobilier. Le premier rôle d’un agent immobilier va être
                d’établir précisément la bonne fourchette de prix pour votre
                logement. En recourant aux connaissances d’un professionnel sur
                son secteur géographique, vous avez accès aux informations sur
                les ventes similaires qui se sont conclues récemment. Dans le
                contexte actuel, cela peut être une aide très précieuse pour
                éviter que votre vente s’éternise et au final vous fasse perdre
                du temps et de l’argent.
              </p>
              <p>
                Utiliser les services d’une agence immobilière c’est utiliser à
                bon escient l’expertise pour la première estimation et pour le
                réajustement éventuel en fonction des retours et de l’évolution
                du marché local. C’est avoir un professionnel qui consacre du
                temps et de la disponibilité pour communiquer, pour échanger
                avec ses contacts, pour faire visiter et savoir mettre en valeur
                les atouts de votre bien. Cela apporte également les
                connaissances juridiques d’un professionnel de ce genre de
                transaction.
              </p>
            </div>
            <div class="col-md-12 text-center"></div>
          </div>
        </div>
      </section>

      <div class=" py-3 ">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto max-w-2xl rounded-3xl ring-1 ring-gray-200  lg:mx-0 lg:flex lg:max-w-none">
            <div class="p-8 sm:p-10 lg:flex-auto">
              <h3 class="text-2xl font-bold tracking-tight text-accent">
                Transaction de Vente / Achat
              </h3>
              <p class="mt-6 leading-7 font-light text-gray-600">
                Transaction immobilière pour l'achat ou la vente d'un bien,
                impliquant le vendeur et l'acheteur.{" "}
              </p>
              <div class="mt-10 flex items-center gap-x-4">
                <h4 class="flex-none font-semibold leading-6 text-accent">
                  Ce qui est inclus
                </h4>
                <div class="h-px flex-auto bg-gray-100"></div>
              </div>
              <ul
                role="list"
                class="mt-8 grid grid-cols-1 gap-4 leading-6 font-light text-gray-600 xl:grid-cols-2 xl:gap-6"
              >
                <li class="flex gap-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    class="h-6 w-5 flex-none text-accent"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  Évaluation professionnelle du bien{" "}
                </li>
                <li class="flex gap-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    class="h-6 w-5 flex-none text-accent"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  Préparation des documents juridiques{" "}
                </li>
                <li class="flex gap-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    class="h-6 w-5 flex-none text-accent"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  Organisation des visites{" "}
                </li>
                <li class="flex gap-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    class="h-6 w-5 flex-none text-accent"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  Coordination avec les notaires ou avocats{" "}
                </li>
                <li class="flex gap-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    class="h-6 w-5 flex-none text-accent"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  Conseils financiers{" "}
                </li>
                <li class="flex gap-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    class="h-6 w-5 flex-none text-accent"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  Gestion des formalités administratives{" "}
                </li>
              </ul>
            </div>
            <div class="my-auto p-2  lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div class="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div class="mx-auto max-w-xs px-8">
                  <p class="text-base font-semibold text-accent">Nos frais</p>
                  <p class="mt-6 flex items-baseline justify-center gap-x-2">
                    <span class="text-5xl font-bold tracking-tight text-accent">
                      2,5%
                    </span>
                    <span class="font-semibold leading-6 tracking-wide text-accent">
                      HT
                    </span>
                  </p>
                  <p class="mt-6 font-light leading-5 text-gray-600">
                    2,5% H.T (3%TTC) sur la valeur totale du bien payable par
                    chacune des parties : Vendeur et Acheteur.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class=" py-3 ">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto max-w-2xl rounded-3xl ring-1 ring-gray-200  lg:mx-0 lg:flex lg:max-w-none">
            <div class="p-8 sm:p-10 lg:flex-auto">
              <h3 class="text-2xl font-bold tracking-tight text-accent">
                Location Longue Durée
              </h3>
              <p class="mt-6 leading-7 font-light text-gray-600">
                Offre de location longue durée pour répondre à vos besoins de
                logement à moyen ou à long terme.{" "}
              </p>
              <div class="mt-10 flex items-center gap-x-4">
                <h4 class="flex-none font-semibold leading-6 text-accent">
                  Ce qui est inclus
                </h4>
                <div class="h-px flex-auto bg-gray-100"></div>
              </div>
              <ul
                role="list"
                class="mt-8 grid grid-cols-1 gap-4 leading-6 font-light text-gray-600 xl:grid-cols-2 xl:gap-6"
              >
                <li class="flex gap-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    class="h-6 w-5 flex-none text-accent"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  Gestion immobilière{" "}
                </li>
                <li class="flex gap-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    class="h-6 w-5 flex-none text-accent"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  Marketing et promotion{" "}
                </li>
                <li class="flex gap-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    class="h-6 w-5 flex-none text-accent"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  Recherche de propriétés{" "}
                </li>
                <li class="flex gap-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                    class="h-6 w-5 flex-none text-accent"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  Négociation des contrats{" "}
                </li>
              </ul>
            </div>
            <div class="my-auto p-2  lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div class="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div class="mx-auto max-w-xs px-8">
                  <p class="text-base font-semibold text-accent">Nos frais</p>
                  <p class="mt-6 flex items-baseline justify-center gap-x-2">
                    <span class="text-5xl font-bold tracking-tight text-accent">
                      1 mois
                    </span>
                    <span class="font-semibold leading-6 tracking-wide text-accent">
                      HT
                    </span>
                  </p>
                  <p class="mt-6 font-light leading-5 text-gray-600">
                    1 mois H.T du montant de la location payable par chacune des
                    parties : Locataire et Bailleur{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="relative my-32 px-6 lg:px-8">
        <div
          class="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 transform-gpu justify-center overflow-hidden blur-3xl sm:bottom-0 sm:right-[calc(50%-6rem)] sm:top-auto sm:translate-y-0 sm:transform-gpu sm:justify-end"
          aria-hidden="true"
        >
          <div
            className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#894d0c]  to-[#cda274] opacity-35"
            style={{
              clipPath:
                "polygon(73.6% 48.6%, 91.7% 88.5%, 100% 53.9%, 97.4% 18.1%, 92.5% 15.4%, 75.7% 36.3%, 55.3% 52.8%, 46.5% 50.9%, 45% 37.4%, 50.3% 13.1%, 21.3% 36.2%, 0.1% 0.1%, 5.4% 49.1%, 21.4% 36.4%, 58.9% 100%, 73.6% 48.6%)",
            }}
          ></div>
        </div>
        <div class="mx-auto max-w-2xl text-center">
          <h2 class="text-3xl font-bold tracking-tight text-accent sm:text-4xl">
            Discutons de votre bien immobilier
          </h2>
          <p class="mx-auto mt-6 max-w-xl text-lg font-light leading-8 text-gray-600">
            Contactez-nous afin de discuter de la façon dont nous pouvons
            collaborer pour vendre votre propriété{" "}
          </p>
          <div class="mt-10 flex justify-center">
            <Link
              to="/contact"
              class=" rounded-md cursor-pointer bg-accent px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-text focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Contactez-nous{" "}
            </Link>
          </div>
        </div>
        <div
          class="absolute left-1/2 right-0 top-full -z-10 hidden -translate-y-1/2 transform-gpu overflow-hidden blur-3xl sm:block"
          aria-hidden="true"
        >
          <div
            class="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#894d0c] to-[#cda274]  opacity-30"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Honoraires;
