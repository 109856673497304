import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { transport } from "../utils/TransportApi";
import Loader from "./Loader/Loader";
import { FaRegShareFromSquare } from "react-icons/fa6";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SocialMedia from "./SocialMedia/SocialMedia";
import ProductsComponent from "./ProductsComponent";
import { IoClose } from "react-icons/io5";
import { ProductsContext } from "../utils/context";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-awesome-reveal";
import { features, interiors, options } from "../utils/helpers";
import { MdOutlineRealEstateAgent } from "react-icons/md";
import { TbTextOrientation } from "react-icons/tb";
import { MdOutlineHomeWork } from "react-icons/md";
import { GiIsland } from "react-icons/gi";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const ProductDetails = () => {
  // Extract the id parameter from the URL
  const { id } = useParams();
  const [productDetails, setProductDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const { products } = useContext(ProductsContext);
  const [t] = useTranslation("global");
  const typesHasRoom = ["maison", "villa", "mini-villa"];

  // form conatct
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      telephone: "",
      demande: "Other",
      message: "",
    },
  });
  const [phone, setPhone] = useState();

  // submit data of form
  const onSubmit = async (data) => {
    try {
      data.telephone = phone;
      await transport.post("/contact", data).then((res) => {
        toast.success("Form submitted successfully!");
        reset();
        setPhone("");
      });
    } catch (error) {
      toast.error("Error submitting form");
      console.error("Error submitting form:", error);
    }
  };
  // Function to toggle the visibility of the gallery modal
  const toggleGalleryModal = () => {
    setShowGalleryModal(true);
  };

  const shareProductDetails = () => {
    const shareData = {
      title: productDetails.name,
      text: `Check out this product: ${productDetails.name}. It is available for ${productDetails.price} DHS/month.`,
      url: window.location.href,
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .catch((error) => console.error("Error sharing", error));
    } else {
      // Fallback: copy to clipboard
      navigator.clipboard
        .writeText(shareData.url)
        .then(() => {
          alert("Link copied to clipboard");
        })
        .catch((err) => {
          console.error("Could not copy text: ", err);
        });
    }
  };

  const [photoIndex, setPhotoIndex] = useState(0);
  useEffect(() => {
    transport
      .get(`products/${id}`)
      .then((res) => {
        setProductDetails(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <Loader />;
  }
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="container mx-auto mt-6">
      <section>
        <div className="container mx-auto p-3">
          <div className="py-6 flex space-y-5 text-center gap-3 xl:text-left justify-center xl:space-y-0 flex-wrap md:flex-nowrap sm:justify-between">
            <div className="relative space-y-5 max-w-3xl 8x-auto xl:mx-0">
              <span className="uppercase text-md font-medium opacity-50">
                {t("Détails")}
              </span>
              <h1 className="text-3xl font-bold leading-snug text-text">
                {productDetails.name}
              </h1>

              <ul className="flex text-[#E1A835] justify-center xl:justify-normal space-x-6 font-light">
                <li className="inline-flex space-x-3 items-center">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M20 9.557V3h-2v2H6V3H4v6.557C2.81 10.25 2 11.525 2 13v4a1 1 0 0 0 1 1h1v4h2v-4h12v4h2v-4h1a1 1 0 0 0 1-1v-4c0-1.475-.811-2.75-2-3.443zM18 7v2h-5V7h5zM6 7h5v2H6V7zm14 9H4v-3c0-1.103.897-2 2-2h12c1.103 0 2 .897 2 2v3z"></path>
                  </svg>
                  <span className="text-md">
                    <span className="hidden sm:inline-block">
                      {t("Chambres")} :{" "}
                    </span>
                    {productDetails.rooms_number}{" "}
                  </span>
                </li>

                <li className="inline-flex space-x-3 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    height="1em"
                    width="1em"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819"
                    ></path>
                  </svg>
                  <span className="text-md">
                    <span className="hidden sm:inline-block">
                      {t("Surface")} :
                    </span>
                    {productDetails.surface} m<sup>2</sup>
                  </span>
                </li>

                <li className="inline-flex space-x-3 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    className="h-5 w-5"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M21 10H7V7c0-1.103.897-2 2-2s2 .897 2 2h2c0-2.206-1.794-4-4-4S5 4.794 5 7v3H3a1 1 0 0 0-1 1v2c0 2.606 1.674 4.823 4 5.65V22h2v-3h8v3h2v-3.35c2.326-.827 4-3.044 4-5.65v-2a1 1 0 0 0-1-1zm-1 3c0 2.206-1.794 4-4 4H8c-2.206 0-4-1.794-4-4v-1h16v1z"
                    />
                  </svg>
                  <span className="text-md">
                    <span className="hidden sm:inline-block">
                      {t("Sale de bain")} :
                    </span>
                    {productDetails.douche}
                  </span>
                </li>
              </ul>
            </div>
            <div className="relative mx-auto xl:mx-0 xl:text-right space-y-5 ">
              <div className="flex gap-2">
                <div className="space-y-5">
                  <span className="uppercase text-md font-medium opacity-50">
                    {t("Prix de")} {productDetails.product_type.name}
                  </span>
                  <h2 className="text-3xl font-bold">
                    {productDetails.price} DHS
                  </h2>
                </div>
              </div>
              <button
                type="button"
                onClick={shareProductDetails}
                className="inline-flex space-x-3 items-center justify-end"
              >
                <FaRegShareFromSquare />
                <span className="text-md text-text hover:text-[#E1A835]">
                  {t("Partager")}
                </span>
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="container md:block mx-auto p-3">
        {showGalleryModal && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
            <div className="relative w-full max-w-[80%] max-h-full mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="relative">
                <div className="flex items-center justify-between p-4 border-b">
                  <h2 className="text-lg font-semibold">{t("Gallerie")}</h2>
                  <button
                    onClick={() => setShowGalleryModal(false)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    &times;
                  </button>
                </div>
                <div className="relative overflow-hidden">
                  <div
                    className="flex transition-transform duration-500"
                    style={{
                      transform: `translateX(-${photoIndex * 100}%)`,
                    }}
                  >
                    {productDetails.images.map((image, index) => (
                      <div key={index} className="w-full flex-shrink-0">
                        <img
                          src={`https://api.essenlux.ma/storage/${image}`}
                          alt={`Image ${index + 1}`}
                          className="object-cover w-full h-[600px]"
                        />
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={() =>
                      setPhotoIndex(
                        (photoIndex + productDetails.images.length - 1) %
                          productDetails.images.length
                      )
                    }
                    className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg text-gray-500 hover:text-gray-700"
                  >
                    &#10094;
                  </button>
                  <button
                    onClick={() =>
                      setPhotoIndex(
                        (photoIndex + 1) % productDetails.images.length
                      )
                    }
                    className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-lg text-gray-500 hover:text-gray-700"
                  >
                    &#10095;
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="pb-10">
          <div className="relative">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              {/* Carousel for mobile view */}
              <div className="block md:hidden">
                <Carousel
                  responsive={{
                    tablet: {
                      breakpoint: { max: 1024, min: 464 },
                      items: 1,
                    },
                    mobile: {
                      breakpoint: { max: 464, min: 0 },
                      items: 1,
                    },
                  }}
                  autoPlay
                  autoPlaySpeed={3000}
                  infinite
                  className="z-0"
                >
                  {productDetails.images.map((image, i) => (
                    <div
                      key={i}
                      data-fslightbox=""
                      href={`https://api.essenlux.ma/storage/${image}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button onClick={toggleGalleryModal}>
                        <img
                          alt="Jolie villa sur la route de Drarga"
                          className="object-cover w-full h-[300px] rounded-lg"
                          src={`https://api.essenlux.ma/storage/${image}`}
                        />
                      </button>
                    </div>
                  ))}
                </Carousel>
                <button
                  onClick={toggleGalleryModal}
                  className="py-2 px-4 border hover:border-primary font-bold mx-auto mt-5 lg:mt-0 lg:absolute xl:bottom-4 lg:bottom-4 right-5 bg-white hover:bg-[#cba660] hover:text-white rounded-md uppercase text-sm flex gap-2 items-center text-primary"
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 16 16"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z"></path>
                  </svg>
                  <span>{t("Plus des photos")}</span>
                </button>
              </div>

              <Zoom animationIn="in">
                <div className="relative hidden md:block">
                  <div
                    className={`grid ${
                      productDetails.images.length <= 2
                        ? "grid-cols-1"
                        : "grid-cols-2 md:gap-2grid-cols-1 md:grid-cols-2 md:gap-2 w-full"
                    }`}
                  >
                    {productDetails.images.length <= 2 ? (
                      <button onClick={toggleGalleryModal}>
                        <img
                          alt="Jolie villa sur la route de Drarga"
                          className="w-full h-[600px]"
                          src={`https://api.essenlux.ma/storage/${productDetails.images[0]}`}
                        />
                      </button>
                    ) : (
                      productDetails.images.slice(0, 4).map((image, i) => (
                        <button onClick={toggleGalleryModal} key={i}>
                          <img
                            alt="Jolie villa sur la route de Drarga"
                            className="object-cover w-full h-[300px]"
                            src={`https://api.essenlux.ma/storage/${image}`}
                          />
                        </button>
                      ))
                    )}
                  </div>
                  <button
                    onClick={toggleGalleryModal}
                    className="py-2 px-4 border hover:border-white font-bold mx-auto mt-5 lg:mt-0 lg:absolute xl:bottom-4 lg:bottom-4 right-5 bg-white hover:bg-[#cba660] hover:text-white rounded-md uppercase text-sm flex gap-2 items-center text-primary"
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z"></path>
                    </svg>
                    <span>{t("Plus des photos")}</span>
                  </button>
                </div>
              </Zoom>
              <Zoom animationIn="in">
                <div className="container mx-auto flex flex-col justify-center items-center gap-4">
                  <div className="bg-[#fff] border p-4 rounded-md shadow-md">
                    <div className=" p-2 text-center w-full">
                      <h1 className="font-semibold text-xl">
                        {t("Ce bien vous intéresse")} ?
                      </h1>
                      <p className="font-light">
                        {t("Contactez-nous pour le visiter")} !
                      </p>
                    </div>
                    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                      <div className="flex flex-wrap -mx-3">
                        <div className="w-full px-3 mb-6">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-first-name"
                          >
                            {t("First Name")}
                          </label>
                          <input
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            id="grid-first-name"
                            type="text"
                            {...register("firstName", { required: true })}
                            placeholder={t("First Name")}
                            required
                          />
                        </div>
                        <div className="w-full px-3 mb-6">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-last-name"
                          >
                            {t("Last Name")}
                          </label>
                          <input
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            id="grid-last-name"
                            type="text"
                            {...register("lastName", { required: true })}
                            placeholder={t("Last Name")}
                            required
                          />
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-3">
                        <div className="w-full px-3 mb-6">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-email"
                          >
                            Email
                          </label>
                          <input
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            id="grid-email"
                            type="email"
                            {...register("email", { required: true })}
                            placeholder="email"
                            required
                          />
                        </div>
                        <div className="w-full px-3 mb-6">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-telephone"
                          >
                            {t("Phone")}
                          </label>
                          <PhoneInput
                            placeholder={t("Phone")}
                            defaultCountry="MA"
                            value={phone}
                            required
                            onChange={setPhone}
                            style={{
                              borderRadius: 3,
                              height: "2.5rem",
                              // padding: 10,
                              lineHeight: 2,
                              paddingLeft: 12,
                            }}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          />
                        </div>
                      </div>
                      {/* Hidden input for demande */}
                      <input
                        type="hidden"
                        {...register("demande")}
                        value="Other"
                      />
                      <div className="flex flex-wrap -mx-3">
                        <div className="w-full px-3 mb-6">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="grid-text"
                            required
                          >
                            {t("Message")}
                          </label>
                          <textarea
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            {...register("message", { required: false })}
                            id="grid-text"
                            placeholder="..."
                            rows={2}
                          />
                        </div>
                      </div>
                      <div className="flex flex-wrap -mx-3">
                        <div className="w-full px-3">
                          <button
                            type="submit"
                            className="bg-[#cba660] hover:bg-[#22213D] text-white font-normal rounded-md py-2 px-8"
                          >
                            {t("Submit")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </Zoom>
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-wrap gap-5">
        <div className="w-full md:w-[50%] lg:w-[60%] divide-y space-y-5 divide">
          <div className="block">
            <h2 className="text-text font-bold text-3xl mt-5 mb-5">
              {t("À propos")}
            </h2>
            <section className="text-text prose font-light text-gray-500">
              <p style={{ whiteSpace: "pre-wrap" }}>
                {productDetails.description}
              </p>
              <p>
                {t("Contactez-nous dès aujourd’hui pour organiser une visite")}!
              </p>
            </section>
          </div>
          <div className="block py-5">
            <h2 className="text-text font-bold text-3xl mb-5">
              {t("L'essentiel")}
            </h2>

            <ul className="text-text grid grid-cols-1 md:grid-cols-2 gap-5 lg:grid-cols-3 place-content-center font-light">
              <li className="flex items-center  space-x-5 ">
                <span>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 16 16"
                    className="text-primary h-6 w-6"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M5.793 1a1 1 0 0 1 1.414 0l.647.646a.5.5 0 1 1-.708.708L6.5 1.707 2 6.207V12.5a.5.5 0 0 0 .5.5.5.5 0 0 1 0 1A1.5 1.5 0 0 1 1 12.5V7.207l-.146.147a.5.5 0 0 1-.708-.708L5.793 1Zm3 1a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708L8.793 2Zm.707.707L5 7.207V13.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5V7.207l-4.5-4.5Z"></path>
                  </svg>
                </span>
                <span>
                  {t("Prix")} : {productDetails.price} DH{" "}
                </span>
              </li>

              <li className="flex items-center space-x-5 ">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="text-primary  h-6 w-6"
                    height="1em"
                    width="1em"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819"
                    ></path>
                  </svg>
                </span>
                <span>
                  {t("Surface")} : {productDetails.surface} m²
                </span>
              </li>

              <li className="flex items-center space-x-5 ">
                <span>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 16 16"
                    className="text-primary  h-6 w-6"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z"></path>
                  </svg>
                </span>
                <span>
                  {t("Type")} : {productDetails.get_sub_types.name}
                </span>
              </li>

              {typesHasRoom.includes(productDetails.get_sub_types.name) && (
                <>
                  <li className="flex items-center space-x-5 ">
                    <span>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        className="text-primary  h-6 w-6"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M20 9.557V3h-2v2H6V3H4v6.557C2.81 10.25 2 11.525 2 13v4a1 1 0 0 0 1 1h1v4h2v-4h12v4h2v-4h1a1 1 0 0 0 1-1v-4c0-1.475-.811-2.75-2-3.443zM18 7v2h-5V7h5zM6 7h5v2H6V7zm14 9H4v-3c0-1.103.897-2 2-2h12c1.103 0 2 .897 2 2v3z"></path>
                      </svg>
                    </span>
                    <span className="hidden sm:inline-block">
                      {t("Sale de bain")} :
                    </span>
                    {productDetails.douche}
                  </li>
                  <li className="flex items-center space-x-5 ">
                    <span>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        className="text-primary  h-6 w-6"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M20 9.557V3h-2v2H6V3H4v6.557C2.81 10.25 2 11.525 2 13v4a1 1 0 0 0 1 1h1v4h2v-4h12v4h2v-4h1a1 1 0 0 0 1-1v-4c0-1.475-.811-2.75-2-3.443zM18 7v2h-5V7h5zM6 7h5v2H6V7zm14 9H4v-3c0-1.103.897-2 2-2h12c1.103 0 2 .897 2 2v3z"></path>
                      </svg>
                    </span>
                    <span>
                      {productDetails.rooms_number > 0
                        ? productDetails.rooms_number
                        : 0}{" "}
                      {t("Chambres")}{" "}
                    </span>
                  </li>
                </>
              )}

              {productDetails.lien_video !== null ? (
                <li className="flex items-center space-x-5 ">
                  <span>
                    <img
                      src={require("../assets/play.png")}
                      className="w-auto h-8"
                      alt="play"
                    />
                  </span>
                  <a
                    className="text-blue-500 underline"
                    href={productDetails.lien_video}
                  >
                    {t("Cliquer pour voir la vidéo")}{" "}
                  </a>
                </li>
              ) : (
                ""
              )}
              {productDetails.floor !== null &&
                productDetails.floor !== undefined && (
                  <li className="flex items-center space-x-5 ">
                    <MdOutlineHomeWork
                      className="w-5 h-5"
                      height="1em"
                      width="1em"
                      style={{ height: "1em", width: "1em" }}
                    />
                    <span className="hidden sm:inline-block">
                      Étage du bien:
                    </span>
                    <p>{productDetails.floor}</p>
                  </li>
                )}

              {productDetails.product_etat && (
                <li className="flex items-center space-x-5 ">
                  <MdOutlineRealEstateAgent
                    className="w-5 h-5"
                    style={{ height: "1em", width: "1em" }}
                  />
                  <span className="hidden sm:inline-block">Etat du bien:</span>
                  <p className="capitalize">{productDetails.product_etat}</p>
                </li>
              )}

              {productDetails.orientation && (
                <li className="flex items-center space-x-5 ">
                  <TbTextOrientation
                    className="w-5 h-5"
                    style={{ height: "1em", width: "1em" }}
                  />
                  <span className="hidden sm:inline-block">Orientation:</span>
                  <p className="capitalize">{productDetails.orientation}</p>
                </li>
              )}

              {productDetails.soil_type && (
                <li className="flex items-center space-x-5 ">
                  <GiIsland
                    className="w-5 h-5"
                    style={{ height: "1em", width: "1em" }}
                  />
                  <span className="hidden sm:inline-block">Type du sol:</span>
                  <p className="capitalize">{productDetails.soil_type}</p>
                </li>
              )}
            </ul>
          </div>
          <div class="block py-5">
            {/* Display features */}
            {JSON.parse(productDetails.main_features) && (
              <div class="block py-5">
                <h2 class="text-text font-bold text-3xl mb-5">
                  Caractéristiques générales
                </h2>
                <h3 class="text-lg text-text mb-5">Ce que propose ce bien</h3>
                <ul class="text-text  grid grid-cols-1 sm:grid-cols-2 gap-5 lg:grid-cols-3 place-content-center">
                  {JSON.parse(productDetails.main_features).map((featureId) => {
                    const feature = features.find((f) => f.id === featureId);
                    return (
                      <li
                        key={featureId}
                        class="flex flex-col space-y-2 p-5  border rounded-xl"
                      >
                        {feature && feature.icon}
                        <span>{feature && feature.label}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            {/* Display interior */}
            {JSON.parse(productDetails.interior) && (
              <div class="block py-5">
                <h2 class="text-text font-bold text-3xl mb-5">
                  Caractéristiques Intérieur
                </h2>
                <ul class="text-text  grid grid-cols-1 sm:grid-cols-2 gap-5 lg:grid-cols-3 place-content-center">
                  {JSON.parse(productDetails.interior).map((interiorId) => {
                    const interior = interiors.find((f) => f.id === interiorId);
                    return (
                      <li
                        key={interiorId}
                        class="flex flex-col space-y-2 p-5  border rounded-xl"
                      >
                        {interior && interior.icon}
                        <span>{interior && interior.label}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            {/* Display options */}
            {JSON.parse(productDetails.additional_options) && (
              <div class="block py-5">
                <h2 class="text-text font-bold text-3xl mb-5">
                  Options supplémentaires
                </h2>
                <ul class="text-text  grid grid-cols-1 sm:grid-cols-2 gap-5 lg:grid-cols-3 place-content-center">
                  {JSON.parse(productDetails.additional_options).map(
                    (optionId) => {
                      const option = options.find((f) => f.id === optionId);
                      return (
                        <li
                          key={optionId}
                          class="flex flex-col space-y-2 p-5  border rounded-xl"
                        >
                          {option && option.icon}
                          <span>{option && option.label}</span>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="w-[100%] md:w-[50%] lg:w-[37%] divide-y space-y-5 justify-center items-start flex">
          <SocialMedia />
        </div>
      </section>
      <Zoom animationIn="in">
        <section className="mb-6 mt-4">
          <h1 className="flex justify-center text-center text-3xl text-accent font-bold">
            {t("CES BIENS POURRAIENT VOUS INTÉRESSER")}
          </h1>
          <ProductsComponent numProducts={3} products={products} />
        </section>
      </Zoom>
    </div>
  );
};

export default ProductDetails;
