import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import bgImage from "../assets/bgSection.webp";
import { Zoom } from "react-awesome-reveal";
import { ProductsContext } from "../utils/context";

const AboutSection = () => {
  const { selectedLanguage } = useContext(ProductsContext);
  const backgroundImageStyle = {
    backgroundImage: `url(${bgImage})`,
  };
  const navigate = useNavigate();
  const [t] = useTranslation("global");

  const handleClick = () => {
    navigate("/about-us");
  };
  return (
    <>
      <div
        className="p-4 lg:p-16 hero h-auto lg:h-[540px] xl:h-[740px] bg-center lg:bg-cover bg-no-repeat bg-fixed xl:rounded-bl-[290px] relative z-20"
        style={backgroundImageStyle}
      >
        <Zoom direction="in">
          <div className="container mx-auto h-full flex items-center justify-center lg:justify-start">
            <div className="hero__text w-full lg:w-[567px] flex flex-col items-center text-center lg:text-left lg:items-start">
              <h1
                className={`text-accent mt-24 lg:mb-12 font-bold text-3xl lg:text-6xl"
                 ${selectedLanguage === "ar" ? "text-right" : "text-left"}`}
                dir={selectedLanguage === "ar" ? "rtl" : "ltr"}
              >
                {" "}
                {t("YOUR REAL ESTATE AGENCY IN AGADIR!")}
              </h1>
              <p
                className={`mb-6 text-sm lg:text-lg font-light text-white md:text-justify lg:text-justify"
                ${selectedLanguage === "ar" ? "text-right" : "text-left"}`}
                dir={selectedLanguage === "ar" ? "rtl" : "ltr"}
              >
                {t(
                  "Welcome to Essen Lux, your trusted real estate agency in Agadir and region"
                )}
              </p>
              <button
                onClick={handleClick}
                className="flex flex-row gap-2 items-center lg:mx-0 bg-accent rounded-full px-[20px] lg:px-[30px] h-[45px] lg:h-[55px] text-white text-sm lg:text-base"
              >
                {t("MORE INFORMATION")} <FaChevronRight />
              </button>
            </div>
          </div>
        </Zoom>
      </div>
    </>
  );
};

export default AboutSection;
