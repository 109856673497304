import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import { AuthProvider } from './utils/context';
import { Fragment } from 'react';
import ErrorPage from './pages/ErrorPage';
import AboutDetails from './components/AboutDetails';
import OurProjects from './pages/OurProjects';
import FixedWhatsAppButton from './components/FixedWhatsAppButton';
import ProductDetails from './components/ProductDetails';
import FilterResults from './pages/FilterResults';
import ProductsBySubtype from './components/ProductsBySubtype';
import ScrollToTop from './components/ScrollToTop';
import PolitiqueConfidentialité from './pages/PolitiqueConfidentialité';
import Services from './pages/Services';
import Honoraires from './components/Honoraires';


function App() {
  return (
    <AuthProvider>
      <Router>
        <RoutesWithFooter />
      </Router>
    </AuthProvider>
  );
}

function RoutesWithFooter() {

  return (
    <Fragment>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about-us" element={<AboutDetails />} />
        <Route path="/projects" element={<OurProjects />} />
        <Route path="/politiqueConfidantialite" element={<PolitiqueConfidentialité />} />
        <Route path="/product-details/:id" element={<ProductDetails />} />
        <Route path="/filter-results" element={<FilterResults />} />
        <Route path="/:type/:subType" element={<ProductsBySubtype />} />
        <Route path="/services" element={<Services />} />
        <Route path="/honoraires" element={<Honoraires />} />


        {/* error page */}
        <Route path="/*" element={<ErrorPage />} />
      </Routes>
      <FixedWhatsAppButton />
      <Footer />
    </Fragment>
  );
}

export default App;