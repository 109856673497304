import {
    FaLeaf,
    FaUmbrellaBeach,
    FaCar,
    FaWater,
    FaMountain,
    FaSwimmingPool,
    FaConciergeBell,
    FaBoxOpen,
    FaCouch,
    FaBuilding,
    FaFire,
    FaSnowflake,
    FaLock,
    FaWindowRestore,
    FaDoorClosed,
    FaMicrochip,
} from "react-icons/fa";
import {
    FaElevator,
    FaFireBurner,
    FaSatelliteDish,
    FaUtensils,
} from "react-icons/fa6";
import { BiSolidFridge } from "react-icons/bi";
import { PiOvenFill, PiWashingMachineFill } from "react-icons/pi";

export const features = [
    { id: "jardin", label: "Jardin", icon: <FaLeaf color="#CBA660" /> },
    {
        id: "terrasse",
        label: "Terrasse",
        icon: <FaUmbrellaBeach color="#CBA660" />,
    },
    { id: "garage", label: "Garage", icon: <FaCar color="#CBA660" /> },
    {
        id: "ascenseur",
        label: "Ascenseur",
        icon: <FaElevator color="#CBA660" />,
    },
    {
        id: "vue_sur_mer",
        label: "Vue sur mer",
        icon: <FaWater color="#CBA660" />,
    },
    {
        id: "vue_sur_les_montagnes",
        label: "Vue sur les montagnes",
        icon: <FaMountain color="#CBA660" />,
    },
    {
        id: "piscine",
        label: "Piscine",
        icon: <FaSwimmingPool color="#CBA660" />,
    },
    {
        id: "concierge",
        label: "Concierge",
        icon: <FaConciergeBell color="#CBA660" />,
    },
    {
        id: "chambre_rangement",
        label: "Chambre rangement",
        icon: <FaBoxOpen color="#CBA660" />,
    },
    { id: "meuble", label: "Meublé", icon: <FaCouch color="#CBA660" /> },
    {
        id: "facade_exterieure",
        label: "Façade extérieure",
        icon: <FaBuilding color="#CBA660" />,
    },
];

export const interiors = [
    {
        id: "salon_Marocain",
        label: "Salon Marocain",
        icon: <FaCouch color="#CBA660" />,
    },
    {
        id: "salon_europeen",
        label: "Salon européen",
        icon: <FaCouch color="#CBA660" />,
    },
    {
        id: "antenne_parabolique",
        label: "Antenne parabolique",
        icon: <FaSatelliteDish color="#CBA660" />,
    },
    { id: "cheminee", label: "Cheminée", icon: <FaFire color="#CBA660" /> },
    {
        id: "climatisation",
        label: "Climatisation",
        icon: <FaSnowflake color="#CBA660" />,
    },
    {
        id: "chauffage_central",
        label: "Chauffage central",
        icon: <FaFireBurner color="#CBA660" />,
    },
    { id: "securite", label: "Sécurité", icon: <FaLock color="#CBA660" /> },
    {
        id: "double_vitrage",
        label: "Double vitrage",
        icon: <FaWindowRestore color="#CBA660" />,
    },
    {
        id: "porte_blindee",
        label: "Porte blindée",
        icon: <FaDoorClosed color="#CBA660" />,
    },
];

export const options = [
    {
        id: "cuisine_equipee",
        label: "Cuisine équipée",
        icon: <FaUtensils color="#CBA660" />,
    },
    {
        id: "refrigerateur",
        label: "Réfrigérateur",
        icon: <BiSolidFridge color="#CBA660" />,
    },
    { id: "four", label: "Four", icon: <PiOvenFill color="#CBA660" /> },
    {
        id: "machine_a_laver",
        label: "Machine à laver",
        icon: <PiWashingMachineFill color="#CBA660" />,
    },
    {
        id: "micro-ondes",
        label: "Micro-ondes",
        icon: <FaMicrochip color="#CBA660" />,
    },
];