import { initReactI18next } from 'react-i18next';
import i18n from 'i18next'
import global_en from '../translations/en/global.json'
import global_es from '../translations/es/global.json'
import global_fr from '../translations/fr/global.json'
import global_ar from '../translations/ar/global.json'

const resources = {
    en: {
        global: global_en
    },
    es: {
        global: global_es
    },
    fr: {
        global: global_fr
    },
    ar: {
        global: global_ar
    }
}

// Function to change the language
const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "fr", // Set the default language here
        fallbackLng: 'en',
        debug: false,
        keySeparator: false,
        interpolation: { escapeValue: false, formatSeparator: ',' },
    })

export { changeLanguage };

export default i18n;