import React, { useContext, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { transport } from "./TransportApi";

// Step 1: Create ProductsContext
export const ProductsContext = React.createContext();

// Step 2: Create custom hook for authentication
export const useProducts = () => {
    const context = useContext(ProductsContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};

// Step 3: AuthProvider component to manage authentication state
export const AuthProvider = ({ children }) => {
    const [products, setProducts] = useState([]);
    const [subTypes, setSubTypes] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('fr');

    // get subtypes
    const fetchSubTypes = async () => {
        try {
            const response = await transport.get('/getAllSubTypes');
            setSubTypes(response.data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await transport.get('/all-products');
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
        fetchSubTypes();
    }, []);

    return (
        <ProductsContext.Provider value={{ products, subTypes, selectedLanguage, setSelectedLanguage }}>
            {children}
        </ProductsContext.Provider>
    );
};