import React from "react";
import { useTranslation } from "react-i18next";

const Modal = ({ isOpen, onClose }) => {
  const [t] = useTranslation("global");
  return (
    <div
      className={`fixed z-50 inset-0 overflow-y-auto ${isOpen ? "" : "hidden"}`}
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          onClick={onClose}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className={`inline-block align-bottom bg-white rounded-2xl px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full ${
            isOpen ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4"
          }`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-3xl text-center mb-5 font-bold text-text leading-6">
                {t("Contactez nous")}
              </h3>
              <div className="mt-2">
                <ul className="flex flex-col gap-3">
                  <li className="inline-flex cursor-pointer justify-center rounded-md border text-[#CA9230] border-transparent bg-amber-50 px-4 py-2 text-sm font-medium hover:bg-[#22213D] focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2">
                    <a
                      href="https://wa.me/212661382615"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>WhatsApp : +212 661 382 615</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="absolute top-1 right-1 z-10">
                <button
                  onClick={onClose}
                  className="flex justify-center rounded-full border border-slate-50 p-2 text-sm font-medium text-primary focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
