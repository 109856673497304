import React, { useState, useEffect } from "react";
import bgImage from "../assets/services.webp";
import { Zoom } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { FaCheck, FaStar } from "react-icons/fa";
import { transport } from "../utils/TransportApi";
import { toast } from "react-toastify";

const PackServices = ({ type }) => {
  const { t } = useTranslation("global");
  const [packs, setPacks] = useState([]);

  const backgroundImageStyle = {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const fetchPacks = async () => {
    try {
      const response = await transport.get("/pack-services", {
        params: { type, status: true },
      });
      setPacks(response.data);
    } catch (error) {
      console.error("Error fetching packs:", error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchPacks();
  }, [type]);

  return (
    <div>
      <section className="p-4">
        <div
          className="relative bg-clip-border bg-white flex h-full min-h-[314px] w-full flex-col items-center justify-center rounded-xl"
          style={backgroundImageStyle}
        >
          <Zoom direction="in">
            <div className="container mx-auto text-center">
              <h2 className="text-4xl font-bold text-white mb-8">
                {t("Choisissez votre formule")}
              </h2>
            </div>
          </Zoom>
        </div>
        <div className="px-10 pt-8 pb-16 -mt-16 lg:px-30 xl:px-40">
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3 w-full">
            {packs.map((pack) =>
              pack.status ? (
                <div
                  key={pack.id}
                  className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-lg hover:shadow-2xl transition-shadow duration-300 lg:h-auto z-30 translate-y-0 h-auto"
                >
                  <div className="p-6 text-start flex flex-col h-full">
                    <div className="flex-grow">
                      <h5 className="antialiased tracking-normal font-sans text-xl font-semibold leading-snug text-accent flex justify-center mt-2 mb-2">
                        {pack.pack_name}
                      </h5>
                      <hr className="w-48 h-px mx-auto my-1 bg-[#006989] border-0 rounded md:my-6 dark:bg-gray-700" />
                      <h6 className="leading-snug flex justify-center mt-5 mb-2 text-[#006989] font-bold">
                        {pack.pack_type}
                      </h6>
                      <p className="antialiased tracking-normal text-base leading-relaxed font-light text-gray-500 mt-4">
                        {pack.description}
                      </p>
                      <ul className="mt-4">
                        {pack.services &&
                          pack.services !== "null" &&
                          JSON.parse(pack.services).map((service, index) => (
                            <li
                              key={index}
                              className="flex items-start w-full flex-row gap-2 py-1 font-light text-gray-500"
                            >
                              <FaCheck className="w-5 h-5 text-inherit text-[#006989]" color="#006989"/>
                              <p className="block w-11/12 antialiased text-base leading-relaxed text-inherit">
                                {service}
                              </p>
                            </li>
                          ))}
                      </ul>
                      <ul className="mt-4">
                        {pack.services_limited &&
                          pack.services_limited !== "null" &&
                          JSON.parse(pack.services_limited).map(
                            (service_limit, index) => (
                              <li
                                key={index + 0.5}
                                className="flex items-start w-full flex-row gap-2 py-1 font-light text-gray-500"
                              >
                                <FaStar className="w-5 h-5" color="#CBA660" />
                                <p className="block w-11/12 antialiased text-base leading-relaxed text-inherit">
                                  {service_limit}
                                </p>
                              </li>
                            )
                          )}
                      </ul>
                    </div>
                    <span className="text-blue-900 flex justify-center mt-5 mb-2 text-2xl font-bold">
                      {pack.price} DHS
                    </span>
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default PackServices;
