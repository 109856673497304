import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { MdBroadcastOnPersonal } from "react-icons/md";
import { IoIosPeople } from "react-icons/io";
import { IoLocation } from "react-icons/io5";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import about from "../assets/about.webp";
import { Zoom } from "react-awesome-reveal";
import sideImage1 from "../assets/WhatsApp Image 2024-06-07 à 19.51.02_7674f6ba.jpg";
import sideImage2 from "../assets/WhatsApp Image 2024-06-07 à 19.51.05_b2353329.jpg";
import { ProductsContext } from "../utils/context";
import { transport } from "../utils/TransportApi";

const AboutDetails = () => {
  const [t] = useTranslation("global");
  const { selectedLanguage } = useContext(ProductsContext);

  const [team, setTeam] = useState([]);

  useEffect(() => {
    const fetchTeam = async () => {
      try {
        const response = await transport.get(
          "https://api.essenlux.ma/api/getAllTeam"
        );
        setTeam(response.data);
      } catch (error) {
        console.error("Error fetching the team data:", error);
      }
    };

    fetchTeam();
  }, []);

  return (
    <div className="bg-[#F7F7F7] h-auto">
      <div className="relative h-64 m-8 overflow-hidden rounded-lg">
        <div className="absolute z-30 flex w-full h-full">
          <div className="relative z-30 w-full px-6 py-8 text-[#CBA660] md:py-10 md:w-1/2 mt-16 uppercase font-semibold">
            <h2 className="text-xl text-center ms:text-3xl md:text-3xl lg:text-3xl xl:text-3xl">
              {t("Qui sommes-nous")}
            </h2>
          </div>
        </div>
        <div className="absolute top-0 right-0 w-full h-full">
          <img
            alt="Snowy mountain lake"
            className="object-cover w-full h-full"
            src={about}
          />
        </div>
      </div>
      <Zoom direction="in">
        <section>
          <div className="flex flex-row flex-wrap w-full px-6 justify-center gap-6">
            <div className="p-10 mt-2 flex flex-col justify-center items-center ">
              <MdBroadcastOnPersonal color="#bd872e" size={50} />
              <h2 className="font-semibold  py-2 px-4 items-center text-xl">
                <CountUp end={100} duration={5} />
              </h2>
              <p className="font-bold text-accent">{t("PERSONNES LOGÉES")}</p>
            </div>
            <div className="p-10 mt-2 flex flex-col justify-center items-center">
              <IoIosPeople color="#bd872e" size={50} />
              <h1 className="font-semibold py-2 px-4 items-center text-xl">
                <CountUp end={92} duration={5} suffix=" %" />
              </h1>
              <p className="font-bold text-accent">{t("CLIENTS SATISFAITS")}</p>
            </div>
            <div className="p-10 mt-2 flex flex-col justify-center items-center">
              <IoLocation color="#bd872e" size={50} />
              <h2 className="font-semibold py-2 px-4 items-center text-xl">
                <CountUp end={96} duration={5} suffix=" %" />
              </h2>
              <p className="font-bold text-accent">{t("PARTOUT AU MAROC")}</p>
            </div>
          </div>
        </section>
      </Zoom>
      <Zoom direction="in">
        <section className="mt-6">
          <div className="container max-w-6xl mx-auto px-6 flex flex-col md:justify-start lg:justify-start gap-6 font-light text-gray-500 sm:text-lg dark:text-gray-400">
            <div className="flex flex-col items-center md:items-center text-justify">
              <p
                className={`
              "w-full max-w-5xl md:text-justify "
              ${selectedLanguage === "ar" ? "text-right" : "text-left"}`}
                dir={selectedLanguage === "ar" ? "rtl" : "ltr"}
              >
                {t("Découvrez EssenLux, votre partenaire")}
              </p>
              <br />
              <p
                className={`w-full max-w-5xl md:text-justify"
              ${selectedLanguage === "ar" ? "text-right" : "text-left"}`}
                dir={selectedLanguage === "ar" ? "rtl" : "ltr"}
              >
                {t("Notre équipe expérimentée")}
              </p>
              <br />
              <p
                className={`w-full max-w-5xl md:text-justify"
              ${selectedLanguage === "ar" ? "text-right" : "text-left"}`}
                dir={selectedLanguage === "ar" ? "rtl" : "ltr"}
              >
                {t("Implantés au cœur d'Agadir")}
              </p>
              <br />
              <p
                className={`w-full max-w-5xl md:text-justify"
              ${selectedLanguage === "ar" ? "text-right" : "text-left"}`}
                dir={selectedLanguage === "ar" ? "rtl" : "ltr"}
              >
                {" "}
                {t("Choisir EssenLux, c'est opter")}
              </p>
            </div>
          </div>
        </section>
      </Zoom>
      <Zoom direction="in">
        <section>
          <div className="container gap-16 items-center py-8 px-4 mx-auto max-w-6xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
            <div className="font-light text-gray-500 sm:text-lg">
              <h2
                className={`mb-4 text-3xl tracking-tight font-extrabold text-[#CBA660] ${
                  selectedLanguage === "ar" ? "text-right" : "text-left"
                }`}
              >
                {t(
                  "Le consulting et le conseil immobilier constituent notre métier"
                )}{" "}
              </h2>
              <p
                className={`mb-4 md:text-justify lg:text-justify"
               ${selectedLanguage === "ar" ? "text-right" : "text-left"}`}
                dir={selectedLanguage === "ar" ? "rtl" : "ltr"}
              >
                {t("Trouvez votre bien immobilier idéal")}{" "}
              </p>
              <p
                className={`md:text-justify lg:text-justify"
               ${selectedLanguage === "ar" ? "text-right" : "text-left"}`}
                dir={selectedLanguage === "ar" ? "rtl" : "ltr"}
              >
                {t("Maximisez la satisfaction de vos besoins")}{" "}
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-8">
              <img
                className="w-full rounded-lg lg:h-[240px] xl:h-[340px]"
                src={sideImage1}
                alt="office content 1"
              />
              <img
                className="mt-4 w-full lg:mt-10 rounded-lglg:h-[240px] xl:h-[340px]  "
                src={sideImage2}
                alt="office content 2"
              />
            </div>
          </div>
        </section>
      </Zoom>

      <div className="py-20">
        <div className="container mx-auto px-6 md:px-12 lg:px-8">
          <div className="space-y-4 text-center">
            <Zoom direction="in">
              <h1 className="text-3xl font-bold md:text-3xl text-accent uppercase">
                {t("Notre équipe")}
              </h1>
            </Zoom>
            <Zoom direction="in">
              <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400 text-[18px] lg:w-8/12 lg:mx-auto">
                {t("Chez EssenLux, nous sommes fiers")}
              </p>
            </Zoom>
          </div>
          <Zoom direction="in">
            <div className="py-10 grid md:gap-12 gap-16 md:grid-cols-4 animate__rotateOut">
              {team.map((member) => (
                <div
                  key={member.id}
                  className="mt-8 border border-accent rounded-lg p-8 border-t-4 space-y-8 group text-center"
                >
                  <div className="w-32 h-32 -mt-16 mx-auto rounded-[2rem] rotate-45 overflow-hidden">
                    <img
                      src={`https://api.essenlux.ma/storage/${member.image}`}
                      alt="team member"
                      loading="lazy"
                      width="640"
                      height="805"
                      className="w-full h-full object-cover -rotate-45 scale-125 mx-auto transition duration-300 group-hover:scale-[1.4]"
                    />
                  </div>
                  <div className="text-center">
                    <h5 className="text-xl font-semibold text-primary">
                      {member.name}
                    </h5>
                    <span className="text-gray-500 text-base">
                      {member.role}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </Zoom>
        </div>
      </div>
    </div>
  );
};

export default AboutDetails;
