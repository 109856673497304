export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export const responsivePartner = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

// List of cities in Morocco
export const cities = [
  { value: "Agadir", label: "Agadir" },
  { value: "Al Hoceïma", label: "Al Hoceïma" },
  { value: "Asilah", label: "Asilah" },
  { value: "Azrou", label: "Azrou" },
  { value: "Beni Mellal", label: "Beni Mellal" },
  { value: "Bouznika", label: "Bouznika" },
  { value: "Casablanca", label: "Casablanca" },
  { value: "Chefchaouen", label: "Chefchaouen" },
  { value: "El Jadida", label: "El Jadida" },
  { value: "Essaouira", label: "Essaouira" },
  { value: "Fès", label: "Fès" },
  { value: "Kenitra", label: "Kenitra" },
  { value: "Khemisset", label: "Khemisset" },
  { value: "Khénifra", label: "Khénifra" },
  { value: "Khouribga", label: "Khouribga" },
  { value: "Ksar El Kebir", label: "Ksar El Kebir" },
  { value: "Laâyoune", label: "Laâyoune" },
  { value: "Marrakech", label: "Marrakech" },
  { value: "Meknès", label: "Meknès" },
  { value: "Mohammedia", label: "Mohammedia" },
  { value: "Nador", label: "Nador" },
  { value: "Ouarzazate", label: "Ouarzazate" },
  { value: "Oujda", label: "Oujda" },
  { value: "Rabat", label: "Rabat" },
  { value: "Safi", label: "Safi" },
  { value: "Salé", label: "Salé" },
  { value: "Settat", label: "Settat" },
  { value: "Tangier", label: "Tangier" },
  { value: "Taza", label: "Taza" },
  { value: "Témara", label: "Témara" },
  { value: "Tétouan", label: "Tétouan" },
  { value: "Tiznit", label: "Tiznit" },
  { value: "Youssoufia", label: "Youssoufia" },
];