import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";

const FixedWhatsAppButton = () => {
  return (
    <div style={{ position: "fixed", bottom: 20, right: 20, zIndex: 50 }}>
      <FloatingWhatsApp
        phoneNumber="+212 661 382 615"
        message="Hello!"
        avatar="imgs/profilewhatsapp.jpg"
        accountName="Essen Lux"
      />
    </div>
  );
};

export default FixedWhatsAppButton;
