import React from "react";
import about from "../assets/sayber.webp";
import { Link } from "react-router-dom";

const PolitiqueConfidentialité = () => {
  return (
    <>
      <div className="relative h-[320px] m-8 overflow-hidden rounded-lg">
        <div className="absolute z-30 flex flex-col items-center w-full h-full px-6 py-8 text-[#CBA660] md:py-10 font-semibold">
          <h2 className="text-xl text-center ms:text-3xl md:text-3xl lg:text-3xl xl:text-3xl mt-16 uppercase">
            Politique de confidentialité
          </h2>
          <div className="max-w-2xl mt-5 font-light text-center text-white">
            <p>
              Nous sommes engagés à garantir la sécurité et la confidentialité
              de vos informations. Consultez notre politique pour comprendre
              comment nous collectons, utilisons et protégeons vos données,
              ainsi que vos droits en matière de confidentialité.
            </p>
          </div>
        </div>
        <div className="absolute top-0 right-0 w-full h-full">
          <img
            alt="Snowy mountain lake"
            className="object-cover w-full h-full"
            src={about}
          />
        </div>
      </div>

      <section class="max-w-3xl mx-auto">
        <div class="p-3 prose md:py-20 space-y-5">
          <h2 class="text-3xl text-center font-bold text-primary uppercase"></h2>
          <div class="text-text">
            <div class="col-md-12">
              <p>
                Chez EssenLux, nous sommes déterminés à protéger la
                confidentialité et la sécurité de vos données personnelles.
                Cette politique de confidentialité explique comment nous
                collectons, utilisons et sécurisons vos informations lorsque
                vous visitez notre site web (
                <Link to="https://essenlux.ma/" className="text-blue-500">
                  https://essenlux.ma/
                </Link>
                ) et interagissez avec nos services.
              </p>
              <br />
              <h2 className="font-bold text-accent">
                1. Collecte d’informations personnelles
              </h2>
              <br />
              <p>
                Nous collectons des informations personnelles lorsque vous
                remplissez un formulaire sur notre site, demandez des
                informations, ou utilisez nos services. Ces informations peuvent
                inclure, sans s’y limiter, votre nom, adresse e-mail, numéro de
                téléphone, et toute autre information pertinente à votre
                recherche immobilière.
              </p>
              <br />
              <h2 className="font-bold text-accent">
                2. Utilisation des informations collectées
              </h2>
              <br />
              <p>
                Nous utilisons les informations que vous nous fournissez pour
                répondre à vos demandes, vous fournir des informations sur nos
                services, personnaliser votre expérience, et améliorer la
                qualité de nos services.
              </p>
              <br />
              <h2 className="font-bold text-accent">
                3. Protection des informations personnelles
              </h2>
              <br />
              <p>
                Nous mettons en place des mesures de sécurité appropriées pour
                protéger vos informations personnelles contre tout accès non
                autorisé, divulgation, altération ou destruction.
              </p>
              <br />
              <h2 className="font-bold text-accent">
                4. Partage d’informations avec des tiers
              </h2>
              <br />
              <p>
                Nous ne vendons, n’échangeons ni ne transférons vos informations
                personnelles à des tiers sans votre consentement, sauf dans le
                but express de vous fournir les services que vous avez demandés.
              </p>
              <br />
              <h2 className="font-bold text-accent">
                5. Cookies et technologies similaires
              </h2>
              <br />
              <p>
                Nous utilisons des cookies et des technologies similaires pour
                améliorer votre expérience sur notre site web. Vous avez la
                possibilité de désactiver les cookies dans les paramètres de
                votre navigateur, mais cela pourrait affecter certaines
                fonctionnalités du site.
              </p>
              <br />
              <h2 className="font-bold text-accent">
                6. Accès et contrôle de vos informations
              </h2>
              <br />
              <p>
                Vous avez le droit d’accéder à vos informations personnelles, de
                les corriger, de les mettre à jour ou de les supprimer à tout
                moment. Contactez-nous à [
                <a
                  href="contact.essenlux@gmail.com"
                  target="_blank"
                  rel="noopener"
                  className="text-blue-800"
                >
                  contact.essenlux@gmail.com
                </a>
                ] pour exercer ces droits.
              </p>
              <br />
              <h2 className="font-bold text-accent">
                7. Modifications de la politique de confidentialité
              </h2>
              <br />
              <p>
                Nous nous réservons le droit de modifier cette politique de
                confidentialité à tout moment. Les modifications seront publiées
                sur cette page avec la date de la dernière mise à jour.
              </p>
              <p>
                En utilisant notre site web, vous consentez à notre politique de
                confidentialité.
              </p>
              <p>
                Pour toute question concernant notre politique de
                confidentialité, veuillez nous contacter à [
                <a
                  href="contact.essenlux@gmail.com"
                  target="_blank"
                  rel="noopener"
                  className="text-blue-800"
                >
                  contact.essenlux@gmail.com
                </a>
                ].
              </p>
              <p>
                Merci de faire confiance à EssenLux pour vos besoins immobiliers
                au Maroc.
              </p>
            </div>
            <div class="col-md-12 text-center"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PolitiqueConfidentialité;
