import React, { useContext, useState } from "react";
import enFlag from "../assets/en.svg";
import esFlag from "../assets/spain.svg";
import frFlag from "../assets/fr.svg";
import maFlag from "../assets/ma.svg";
import { changeLanguage } from "../translations/i18n";
import { ProductsContext } from "../utils/context";

const LanguageDropdown = () => {
  const { setSelectedLanguage } = useContext(ProductsContext);
  const languages = [
    { code: "fr", name: "French", icon: frFlag },
    { code: "en", name: "English", icon: enFlag },
    { code: "es", name: "Spanish", icon: esFlag },
    { code: "ar", name: "العربية", icon: maFlag },
  ];
  const defaultLangFlag = (
    <img src={languages[0].icon} height="30" width="30" alt="nope" />
  );
  const [langFlag, setLangFlag] = useState(defaultLangFlag);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleChange = (e, selectedLanguage) => {
    const selectedLanguageIcon = selectedLanguage.icon;
    setLangFlag(
      <img src={selectedLanguageIcon} height="30" width="30" alt="nope" />
    );

    changeLanguage(selectedLanguage.code);
    setSelectedLanguage(selectedLanguage.code);
    setShowDropdown(false);
  };

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={() => setShowDropdown(!showDropdown)}
        className="rounded-full overflow-hidden border border-gray-400 w-7 h-7"
      >
        {langFlag}
      </button>
      {showDropdown && (
        <ul className="block absolute w-32 top-10 right-1 z-50 appearance-none bg-white hover:border-gray-500 px-2 py-2  rounded shadow leading-tight focus:outline-none focus:shadow-outline">
          {languages.map((lang) => (
            <li
              key={lang.code}
              value={lang.code}
              onClick={(e) => handleChange(e, lang)}
              className="flex flex-row p-2 gap-2 py-1 cursor-pointer hover:bg-[#f9fafb] text-sm font-medium text-gray-900 rounded-xl duration-300"
            >
              <img src={lang.icon} alt="" width={25} height={25} />
              {lang.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageDropdown;
