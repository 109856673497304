import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { CiMenuFries } from "react-icons/ci";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import LanguageDropdown from "./LanguageDropdown";
import { useTranslation } from "react-i18next";
import { transport } from "../utils/TransportApi";

const Navbar = () => {
  // State to manage the navbar's visibility
  const [nav, setNav] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [t] = useTranslation("global");
  const [activeItem, setActiveItem] = useState(0);
  const [navBarItems, setNavBarItems] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  // Toggle function to handle the navbar's display
  const handleNav = () => {
    setNav(!nav);
  };

  // Function to handle setting active item
  const handleSetActiveItem = (itemId) => {
    setActiveItem(itemId);
  };

  // Function to handle opening and closing dropdown
  const handleDropdown = (itemId) => {
    if (dropdownOpen === itemId) {
      setDropdownOpen(null);
      setNav(false);
    } else {
      setDropdownOpen(itemId);
      setNav(false);
    }
  };

  useEffect(() => {
    const fetchNavItems = async () => {
      try {
        const response = await transport.get("/getAllTypes");
        setNavBarItems(response.data);
      } catch (error) {
        console.error("Error fetching product types:", error);
      }
    };

    fetchNavItems();
  }, []);

  const handleSubItemClick = (link) => {
    navigate(link);
    setNav(false); // Close mobile nav on link click
  };

  // Array containing navigation items with sub-items
  const navItems = [
    {
      id: 1,
      name: t("Vente"),
      sub_types: [
        { name: t("Villas"), link: "buy/villas" },
        { name: t("MiniVillas"), link: "buy/miniVillas" },
        { name: t("Houses"), link: "buy/houses" },
        { name: t("Lands"), link: "buy/lands" },
        { name: t("Farms"), link: "buy/farms" },
        { name: t("Riads"), link: "buy/riads" },
        { name: t("Offices"), link: "buy/offices" },
      ],
    },
    {
      id: 2,
      name: t("Location"),
      sub_types: [
        { name: t("Villas"), link: "rent/villas" },
        { name: t("MiniVillas"), link: "rent/miniVillas" },
        { name: t("Houses"), link: "rent/houses" },
        { name: t("Farms"), link: "rent/farms" },
        { name: t("Riads"), link: "rent/riads" },
      ],
    },
    {
      id: 3,
      name: t("Projets"),
      sub_types: [
        { name: t("Buildings"), link: "projects/building" },
        { name: t("guest house"), link: "projects/guest-house" },
        { name: t("Farm") },
        { name: t("Commercial projects"), link: "projects/commerce" },
      ],
      icon: <MdKeyboardArrowDown />,
    },
  ];

  useEffect(() => {
    const path = location.pathname;
    if (path === "/") {
      setActiveItem(0);
    } else if (path === "/about-us") {
      setActiveItem(999);
    } else if (path === "/services") {
      setActiveItem(1);
    } else if (path === "/contact") {
      setActiveItem(99);
    } else {
      // Check if the path matches any of the nav items or their sub-items
      const matchingItem = combinedNavItems.find(item => 
        path.startsWith(`/${item.name.toLowerCase()}`) ||
        item.sub_types?.some(subItem => 
          path === `/${item.name.toLowerCase()}/${subItem.name.toLowerCase()}`
        )
      );
      setActiveItem(matchingItem ? matchingItem.id : null);
    }
  }, [location.pathname]);

  // Combine static and fetched nav items
  const combinedNavItems = navBarItems.length ? navBarItems : navItems;

  return (
    <div
      className={`sticky top-0 h-[90px] shadow-xl z-50 bg-white flex justify-between items-center max-w-full mx-auto px-10 text-black  left-0 right-0`}
    >
      {/* Logo */}
      <div className="w-50 md:px-16 mt-2">
        <Link to="/" className="w-16 m-4" onClick={() => handleSetActiveItem(0)}>
          <img
            loading="lazy"
            src="/imgs/New EssenLux logo.png"
            className="w-16 m-4"
            alt=""
          />
        </Link>
      </div>
      <div className="flex flex-row align-middle items-center gap-2">
        {/* Desktop Navigation */}
        <ul className={`hidden md:flex ${nav ? "hidden" : "flex"}`}>
          <li className="relative group w-[80px]">
            <Link
              to="/"
              onClick={() => handleSetActiveItem(0)}
              className={`p-2 m-2 cursor-pointer duration-150 hover:text-black flex flex-row items-center gap-1 text-base font-medium hover:text-opacity-100 border-b-2 ${
                activeItem === 0 ? "text-black border-accent" : "border-transparent"
              }`}
            >
              {t("Home")}
            </Link>
          </li>
          <li className="relative group w-[90px]">
            <Link
              to="/about-us"
              onClick={() => handleSetActiveItem(999)}
              className={`p-2 m-2 w-full items-start cursor-pointer duration-150 hover:text-black flex flex-row text-base font-medium hover:text-opacity-100 border-b-2 ${
                activeItem === 999 ? "text-black border-accent" : "border-transparent"
              }`}
            >
              {t("À propos")}
            </Link>
          </li>
          <li className="relative group w-[90px]">
            <Link
              to="/services"
              onClick={() => handleSetActiveItem(1)}
              className={`p-2 m-2 cursor-pointer duration-150 hover:text-black flex flex-row items-center gap-1 text-base font-medium hover:text-opacity-100  border-b-2 ${
                activeItem === 1 ? "text-black border-accent" : "border-transparent"
              }`}
            >
              {t("Services")}
            </Link>
          </li>
          {combinedNavItems.map((item) => (
            <li key={item.id} className="relative group w-[90px]">
              <Link
                to={item.link || "#"}
                onClick={() => handleSetActiveItem(item.id)}
                className={`p-2 m-2 cursor-pointer duration-150 hover:text-black flex flex-row items-center gap-1 text-base font-medium hover:text-opacity-100 border-b-2 ${
                  activeItem === item.id
                    ? "text-black border-accent"
                    : "border-transparent"
                }`}
              >
                {item.name}
                <MdKeyboardArrowDown />
              </Link>
              {item.sub_types && (
                <ul
                  className="p-2 w-48 absolute hidden group-hover:block bg-[#fff] border border-gray-200 rounded-xl"
                  style={{ zIndex: 3 }}
                >
                  {item.sub_types.map((subItem, index) => (
                    <li
                      key={index}
                      onClick={() =>
                        handleSubItemClick(
                          `/${item.name.toLowerCase()}/${subItem.name.toLowerCase()}`
                        )
                      }
                      className="p-2 hover:bg-[#f9fafb] text-sm font-medium text-gray-900 rounded-xl duration-300 hover:text-black cursor-pointer"
                    >
                      <Link
                        to={`/${item.name.toLowerCase()}/${subItem.name.toLowerCase()}`}
                        className="hover:text-black"
                      >
                        {subItem.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
          <li className="relative group w-[90px]">
            <Link
              to="/contact"
              onClick={() => handleSetActiveItem(99)}
              className={`p-2 m-2 cursor-pointer duration-150 hover:text-black flex flex-row items-center gap-1 text-base font-medium hover:text-opacity-100 border-b-2 ${
                activeItem === 99 ? "text-black border-accent" : "border-transparent"
              }`}
            >
              {t("Contact")}
            </Link>
          </li>
        </ul>

        <div className="hidden md:block">
          <LanguageDropdown />
        </div>
      </div>
      {/* Mobile Navigation Icon */}
      <div onClick={handleNav} className="block md:hidden">
        {nav ? <IoCloseSharp size={20} /> : <CiMenuFries size={20} />}
      </div>

      {/* Mobile Navigation Menu */}
      <ul
        className={
          nav
            ? "fixed left-0 top-0 w-[100%] h-full border-r border-r-gray-900 bg-[#fff] ease-in-out duration-500 z-10 overflow-auto"
            : "hidden md:hidden"
        }
      >
        <div className="flex flex-row justify-between p-4">
          {/* Mobile Logo */}
          <img src="/imgs/New EssenLux logo.png" className="w-20 m-4" alt="" />
          {/* Close Icon */}
          <button onClick={handleNav}>
            <IoCloseSharp size={20} />
          </button>
        </div>
        {/* Mobile Navigation Items */}
        <li className="relative group space-y-1 py-2">
          <Link to="/">
            <button
              onClick={() => handleDropdown(0)}
              className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5  hover:bg-gray-50 duration-300 text-base font-semibold leading-7 text-gray-900  cursor-pointer flex-row gap-1"
            >
              {t("Home")}
            </button>
          </Link>
        </li>{" "}
        <li className="relative group space-y-1 py-2">
          <Link to="/about-us">
            <button
              onClick={() => handleDropdown(999)}
              className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5  hover:bg-gray-50 duration-300 text-base font-semibold leading-7 text-gray-900  cursor-pointer flex-row gap-1"
            >
              {t("À propos")}
            </button>
          </Link>
        </li>
        <li className="relative group space-y-1 py-2">
          <Link to="/services">
            <button
              onClick={() => handleDropdown(999)}
              className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5  hover:bg-gray-50 duration-300 text-base font-semibold leading-7 text-gray-900  cursor-pointer flex-row gap-1"
            >
              {t("Services")}
            </button>
          </Link>
        </li>
        {combinedNavItems.map((item) => (
          <li key={item.id} className="relative group space-y-1 py-2">
            <Link to={item.link || "#"}>
              <span
                onClick={() => handleDropdown(item.id)}
                className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5  hover:bg-gray-50 duration-300 text-base font-semibold leading-7 text-gray-900  cursor-pointer flex-row gap-1"
              >
                {item.name}
                {item.icon}
              </span>
            </Link>
            {item.sub_types && dropdownOpen === item.id && (
              <ul className="bg-[#fff] border-gray-200 p-2 w-full">
                {item.sub_types.map((subItem, index) => (
                  <li
                    key={index}
                    onClick={() =>
                      handleSubItemClick(
                        `/${item.name.toLowerCase()}/${subItem.name.toLowerCase()}`
                      )
                    }
                    className="p-4 hover:bg-gray-50 duration-300 hover:text-black cursor-pointer"
                  >
                    <Link
                      to={`/${item.name.toLowerCase()}/${subItem.name.toLowerCase()}`}
                      className="hover:text-black"
                    >
                      {subItem.name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
        <li className="relative group space-y-1 py-2">
          <Link to="/contact">
            <button
              onClick={() => handleSetActiveItem(99)}
              className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5  hover:bg-gray-50 duration-300 text-base font-semibold leading-7 text-gray-900  cursor-pointer flex-row gap-1"
            >
              {t("Contact")}
            </button>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
