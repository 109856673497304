import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-awesome-reveal";
import Editorial from "../assets/rt.webp";
import { ProductsContext } from "../utils/context";

const EditorialSection = () => {
  const { t } = useTranslation("global");
  const { selectedLanguage } = useContext(ProductsContext);

  return (
    <section className="about mt-[30px] xl:mt-[80px] relative z-20">
      <div className="container mx-auto px-4 xl:px-0">
        <div className="flex flex-col xl:flex-row text-center xl:text-left justify-center items-center gap-8 xl:gap-[270px]">
          <div className="order-2 xl:order-none max-w-xl xl:max-w-[410px] flex flex-col justify-center items-center xl:items-start gap-4 xl:gap-6">
            <Zoom direction="in">
              <h2
                className={`text-center xl:text-left font-bold leading-tight text-3xl xl:text-5xl mb-4 xl:mb-6 ${
                  selectedLanguage === "ar" ? "text-right" : "text-left"
                }`}
              >
                <b className="text-accent">EssenLux</b> -{" "}
                {t("Your partner for all your real estate projects")}
              </h2>
            </Zoom>
            <Zoom direction="in">
              <p
                className={`font-light text-gray-500 sm:text-lg dark:text-gray-400 ${
                  selectedLanguage === "ar" ? "text-right" : "text-left"
                }`}
                dir={selectedLanguage === "ar" ? "rtl" : "ltr"}
              >
                {t("Come and meet us in our EssenLux agency to discuss and discuss your real estate project")}
                !
              </p>
            </Zoom>
          </div>
          <Zoom direction="in">
            <div className="order-1 xl:order-none max-w-[300px] mx-auto xl:max-w-[400px] xl:mx-0">
              <img src={Editorial} alt="EssenLux" className="" />
            </div>
          </Zoom>
        </div>
      </div>
    </section>
  );
};

export default EditorialSection;
