import React from "react";
import "./style.css";
import {FaTiktok } from "react-icons/fa6";

const SocialMedia = () => {
  return (
    <div className="main">
      <div className="up">
        <a
          href="https://www.instagram.com/immoessenlux/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="card1">
            <svg
              className="instagram"
              fillRule="nonzero"
              height="30px"
              width="30px"
              viewBox="0,0,256,256"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                style={{ mixBlendMode: "normal" }}
                textAnchor="none"
                fontSize="none"
                fontWeight="none"
                fontFamily="none"
                strokeDashoffset="0"
                strokeDasharray=""
                strokeMiterlimit="10"
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeWidth="1"
                stroke="none"
                fillRule="nonzero"
              >
                <g transform="scale(8,8)">
                  <path d="M11.46875,5c-3.55078,0 -6.46875,2.91406 -6.46875,6.46875v9.0625c0,3.55078 2.91406,6.46875 6.46875,6.46875h9.0625c3.55078,0 6.46875,-2.91406 6.46875,-6.46875v-9.0625c0,-3.55078 -2.91406,-6.46875 -6.46875,-6.46875zM11.46875,7h9.0625c2.47266,0 4.46875,1.99609 4.46875,4.46875v9.0625c0,2.47266 -1.99609,4.46875 -4.46875,4.46875h-9.0625c-2.47266,0 -4.46875,-1.99609 -4.46875,-4.46875v-9.0625c0,-2.47266 1.99609,-4.46875 4.46875,-4.46875zM21.90625,9.1875c-0.50391,0 -0.90625,0.40234 -0.90625,0.90625c0,0.50391 0.40234,0.90625 0.90625,0.90625c0.50391,0 0.90625,-0.40234 0.90625,-0.90625c0,-0.50391 -0.40234,-0.90625 -0.90625,-0.90625zM16,10c-3.30078,0 -6,2.69922 -6,6c0,3.30078 2.69922,6 6,6c3.30078,0 6,-2.69922 6,-6c0,-3.30078 -2.69922,-6 -6,-6zM16,12c2.22266,0 4,1.77734 4,4c0,2.22266 -1.77734,4 -4,4c-2.22266,0 -4,-1.77734 -4,-4c0,-2.22266 1.77734,-4 4,-4z"></path>
                </g>
              </g>
            </svg>
          </button>
        </a>
        <a
          href="https://www.facebook.com/share/NQGZKx2Y77J8cPNr/?mibextid=qi2Omg"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="card2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              height="24"
              className="facebook"
              width="24"
            >
              <path d="M9.19795 21.5H13.198V13.4901H16.8021L17.198 9.50977H13.198V7.5C13.198 6.94772 13.6457 6.5 14.198 6.5H17.198V2.5H14.198C11.4365 2.5 9.19795 4.73858 9.19795 7.5V9.50977H7.19795L6.80206 13.4901H9.19795V21.5Z"></path>
            </svg>
          </button>
        </a>
      </div>
      <div className="down">
        <a
          href="https://www.youtube.com/@essenlux"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="card3">
            <svg
              width="30"
              height="30"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              className="youtube"
            >
              <path d="M19.615,3.184c-0.643-0.624-1.456-1.004-2.34-1.107c-2.068-0.234-5.186-0.234-5.186-0.234s-3.118,0-5.186,0.234 c-0.884,0.103-1.697,0.483-2.34,1.107c-0.644,0.643-1.025,1.456-1.128,2.34c-0.234,2.068-0.234,5.186-0.234,5.186 s0,3.118,0.234,5.186c0.103,0.884,0.484,1.697,1.128,2.34c0.643,0.624,1.456,1.004,2.34,1.107 c2.068,0.234,5.186,0.234,5.186,0.234s3.118,0,5.186-0.234c0.884-0.103,1.697-0.484,2.34-1.107 c0.644-0.643,1.025-1.456,1.128-2.34c0.234-2.068,0.234-5.186,0.234-5.186s0-3.118-0.234-5.186 C20.64,4.64,20.259,3.827,19.615,3.184z M10,15.48v-6.96L15.5,12L10,15.48z"></path>
            </svg>
          </button>
        </a>
        <a
          href="https://www.tiktok.com/@essenlux.ma?_t=8n05Q8oeNmf&_r=1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="card4 shadow-lg shadow-red-600">
          <FaTiktok size={20}  className="tiktok"/>

          </button>
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
