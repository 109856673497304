import React, { useState, useEffect, useContext } from "react";
import PackServices from "../components/PackServices";
import { Zoom } from "react-awesome-reveal";
import { transport } from "../utils/TransportApi";
import { ProductsContext } from "../utils/context";

const Services = () => {
  const [services, setServices] = useState([]);
  const { selectedLanguage } = useContext(ProductsContext);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await transport.get(`/services`);
        console.log(" response", response.data.data);
        setServices(response.data.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des services:", error);
      }
    };

    fetchServices();
  }, []);

  return (
    <div>
      {/* <!-- component --> */}
      <section className="py-20 bg-white">
        <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
          <Zoom direction="in">
            <div className="relative">
              <h2 className="w-full text-3xl text-accent font-bold text-center sm:text-4xl md:text-5xl">
                Découvrez Nos Services Immobiliers
              </h2>
              <p className="w-full py-8 mx-auto -mt-2 text-lg text-center text-gray-600 font-light intro sm:max-w-3xl">
                Essen Lux est une agence immobilière qui se distingue par une
                gamme de services premium destinée à répondre aux besoins
                spécifiques de sa clientèle exigeante. Voici une présentation
                détaillée des services proposés par l'agence immobilière Essen
                Lux :
              </p>
            </div>
          </Zoom>
          {services.map((service) => (
            <div
              key={service.id}
              className={`flex flex-col mb-8 animated fadeIn gap-4 justify-between w-full ${
                service.id % 2 === 1 ? "sm:flex-row-reverse" : "sm:flex-row"
              }`}
            >
              <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
                <img
                  className="rounded-lg shadow-xl"
                  src={`https://api.essenlux.ma/storage/${service.image}`}
                  alt=""
                />
              </div>
              <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-1/2 sm:pr-16">
                <h3
                  className={`mt-2 text-2xl md:text-4xl text-accent ${
                    selectedLanguage === "ar" ? "text-right" : "text-left"
                  }`}
                >
                  {service[`service_name_${selectedLanguage}`]
                    ? service[`service_name_${selectedLanguage}`]
                    : service.service_name_fr}
                </h3>
                <h6
                  className={`mt-2 text-xl md:text-xl text-accent ${
                    selectedLanguage === "ar" ? "text-right" : "text-left"
                  }`}
                >
                  {service[`title_${selectedLanguage}`]
                    ? service[`title_${selectedLanguage}`]
                    : service.title_fr}
                </h6>
                <p
                  className={`mt-5 text-lg font-light text-gray-600 ${
                    selectedLanguage === "ar" ? "text-right" : "text-left"
                  }`}
                >
                  {service[`description_${selectedLanguage}`]
                    ? service[`description_${selectedLanguage}`]
                    : service.description_fr}
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>
      <PackServices />
    </div>
  );
};

export default Services;
