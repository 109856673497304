import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { transport } from "../utils/TransportApi";
import { useTranslation } from "react-i18next";
import { responsivePartner } from "../utils/Objects";
import { Zoom } from "react-awesome-reveal";

const PartnerSection = () => {
  const [partners, setPartners] = useState([]);
  const [t] = useTranslation("global");

  const getPartners = async () => {
    try {
      const response = await transport.get("/getAllPartners");
      setPartners(response.data.partners.data);
    } catch (error) {
      console.error("Failed to fetch partners:", error);
    }
  };

  function getInitials(partnerName) {
    const words = partnerName.split(" ");
    if (words.length === 1) {
      return partnerName.substring(0, 2);
    } else {
      return words.map((word) => word[0]).join("");
    }
  }

  useEffect(() => {
    getPartners();
  }, []);
  return (
    <div className="bg-gray-50 py-8 md:py-16 mt-12">
    <div className="container mx-auto px-4">
    <Zoom direction="in">
        <div className="text-center mb-8">
          <h2 className="font-bold text-3xl md:text-3xl my-4 text-accent">
            {t("OUR PARTNERS")}
          </h2>
        </div>
      </Zoom>
      <Zoom direction="in">
        <Carousel
          responsive={responsivePartner}
          autoPlay={true}
          autoPlaySpeed={3000}
          infinite={true}
          showDots={false}
          swipeable={true}
          removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
          className="w-full"
        >
          {partners.map((partner) => (
            <div
              key={partner.id}
              className="p-4 border flex flex-col items-center rounded-lg max-w-xs mx-2 bg-white shadow-md"
            >
              {partner.logo ? (
                <img
                  src={`https://api.essenlux.ma/storage/${partner.logo}`}
                  alt="Partner logo"
                  className="w-20 h-20 rounded-lg mb-4"
                />
              ) : (
                <div className="flex-shrink-0 h-auto w-auto">
                  <span className="p-3 h-16 w-16 text-2xl flex justify-center items-center rounded-full capitalize font-bold text-gray-500 bg-gray-200 cursor-pointer">
                    {getInitials(partner.partner_name)}
                  </span>
                </div>
              )}
            </div>
          ))}
        </Carousel>
      </Zoom>
    </div>
  </div>
  );
};

export default PartnerSection;
