import React from "react";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-awesome-reveal";

const FeatureSection = () => {
  const [t] = useTranslation("global");
  return (
<section className="steps h-auto mt-20">
  <div className="container mx-auto px-4">
  <Zoom direction="in">
    <h1 className="font-bold text-3xl md:text-3xl my-4 text-accent text-center mb-12 uppercase">
      {t("Why choose EssenLux ?")}
    </h1>
    </Zoom>
    <Zoom direction="in">
    <div className="grid grid-cols-1 gap-12 md:grid-cols-2 xl:grid-cols-3">
        <div className="steps__step flex flex-col justify-between text-center border border-md p-6 rounded-xl border-[#CBA660] min-h-full">
          <div className="flex-1">
            <div className="mb-4">
              <i className="ri-compasses-2-line text-center text-5xl text-accent"></i>
            </div>
            <h1 className="text-primary font-semibold  mb-5">
              {t("Expertise and Knowledge")}
            </h1>
            <p className="mb-5 max-w-md mx-auto font-light text-gray-500 sm:text-lg dark:text-gray-400">
              {t("Our deep understanding of the local market allows us to provide accurate evaluations and insightful advice for buying or selling properties.")}
            </p>
          </div>
        </div>
        <div className="steps__step flex flex-col justify-between text-center border border-md p-6 rounded-xl border-[#CBA660] min-h-full">
          <div className="flex-1">
            <div className="mb-4">
              <i className="ri-magic-line text-center text-5xl text-accent"></i>
            </div>
            <h1 className="text-primary font-semibold mb-5">
              {t("Reputation and Reliability")}
            </h1>
            <p className="mb-5 max-w-md mx-auto font-light text-gray-500 sm:text-lg dark:text-gray-400">
              {t("Our excellent reputation and numerous client recommendations reflect our professionalism and trustworthiness")}
            </p>
          </div>
        </div>
        <div className="steps__step flex flex-col justify-between text-center border border-md p-6 rounded-xl border-[#CBA660] min-h-full">
          <div className="flex-1">
            <div className="mb-4">
              <i className="ri-tools-line text-center text-5xl text-accent"></i>
            </div>
            <h1 className="text-primary font-semibold mb-5">
              {t("Innovative Marketing and Visibility")}
            </h1>
            <p className="mb-5 max-w-md mx-auto font-light text-gray-500 sm:text-lg dark:text-gray-400">
              {t("Our extensive network and diverse property portfolio offer clients a wide range of choices, whether they're looking for a primary residence, secondary home, or investment.")}
            </p>
          </div>
        </div>
    </div>
    </Zoom>
  </div>
</section>



  );
};

export default FeatureSection;
